<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" class="py-0">
      <v-tabs
        v-model="tab"
        :vertical="$vuetify.breakpoint.xs"
      >
        <v-tab
          v-for="(item, index) in tabs.filter(element => element.permiso == 1)"
          :key="index"
        >
          {{ item.nombre }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in tabs"
          :key="index"
        >
          <IndicesStock v-if="item.id == 1" />
          <IndicesGerenciales v-else-if="item.id == 2" />
          <RankingVendedores v-else-if="item.id == 3"/>
          <div v-else>Sin Definir</div>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import IndicesStock from '../../components/dashboard/IndicesStock'
import IndicesGerenciales from '../indices/IndicesGerenciales.vue'
import RankingVendedores from '../objetivos/RankingVendedores.vue'
export default {
  data () {
    return {
      tab: null,
      tabs: [{id: 1, nombre: 'Stock', permiso: 1}, {id: 2, nombre: 'Indices Gerenciales', permiso: 1}, {id: 3, nombre: 'Comerciales', permiso: 0}]
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('get_regiones')
    await this.$store.dispatch('genericosAcc/get_sucursales')
    await this.$store.dispatch('rubros/get_rubros_habilitados')
    await this.$store.dispatch('categorias/get_categorias_habilitadas')
    // 02-09-2024 por MMURILLO, obtengo el permiso para ver el ranking de vendedores
    let permisoPeticion = await this.$store.dispatch('objetivos/get_permiso_ranking')
    if (permisoPeticion.resultado == 0){
      this.$store.state.loading = false
      this.$store.dispatch('show_snackbar', {
        text: permisoPeticion.msj,
        color: 'error',
      })
      return
    }
    let unElem = this.tabs.filter(element => element.id == 3)
    let pos = this.tabs.indexOf(unElem[0])
    this.tabs[pos].permiso = permisoPeticion.permiso
    this.$store.state.loading = false
  },
  components: {
    IndicesStock,
    IndicesGerenciales,
    RankingVendedores
  }
}
</script>