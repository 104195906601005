<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <!-- Titulo -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                Filtros
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscar">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    <v-autocomplete
                      v-model="selectedSucursal"
                      label="Sucursal"
                      item-text="sucursal_nombre"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="getLocales(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    <v-autocomplete
                      v-model="selectedCanal"
                      label="Canal"
                      item-text="canal_nombre"
                      :items="canales"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                      @change="getLocales(1)"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" class="py-1">
                    <v-autocomplete
                      v-model="selectedLocales"
                      label="Pto. de Venta"
                      item-text="local_nombre"
                      :items="locales"
                      hide-details
                      outlined
                      dense
                      clearable
                      chips
                      deletable-chips
                      small-chips
                      multiple
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <BtnFiltro
                      nombre="Buscar"
                      icono="fas fa-search"
                      clase="mt-0"
                      :loading="load"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- Seccion GMROI -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-title>
                GMROI
              </v-card-title>
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <GMROI 
                      :datos="gmRoiData"
                    />
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="encabezadoRoi"
                      :items="itemsRoi"
                      dense
                    >
                      <template v-slot:items="props">
                        <td class="text-xs-center">{{ props.item.local_nombre }}</td>
                        <td v-for="(col,index) in cantPeriodos" :key="index" class="text-xs-center">{{ props.item['col' + index] }}</td>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Seccion GMROS -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-title>
                GMROS
              </v-card-title>
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <GMROS 
                      :datos="gmRosData"
                    />
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="encabezadoRos"
                      :items="itemsRos"
                      dense
                    >
                      <template v-slot:items="props">
                        <td class="text-xs-center">{{ props.item.local_nombre }}</td>
                        <td v-for="(col,index) in cantPeriodos" :key="index" class="text-xs-center">{{ props.item['col' + index] }}</td>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Seccion GMROL -->
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-title>
                GMROL
              </v-card-title>
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <GMROL 
                      :datos="gmRolData"
                    />
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-data-table
                      class="cebra elevation-2 mt-2"
                      :headers="encabezadoRol"
                      :items="itemsRol"
                      dense
                    >
                      <template v-slot:items="props">
                        <td class="text-xs-center">{{ props.item.local_nombre }}</td>
                        <td v-for="(col,index) in cantPeriodos" :key="index" class="text-xs-center">{{ props.item['col' + index] }}</td>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Seccion GMROS vs GMROI Sucursales -->
        <v-expansion-panels v-model="panel1" class="pt-2">
          <v-expansion-panel readonly>
            <!-- Titulo -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                GMROS vs GMROI Sucursales
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscarIndicesSuc">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="4" md="4" class="py-2">
                    <v-autocomplete
                      v-model="selectCanal"
                      label="Canal"
                      item-text="canal_nombre"
                      :items="canales"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" class="pt-0">
                    <BtnFiltro
                      nombre="Buscar"
                      icono="fas fa-search"
                      clase="mt-0"
                      :loading="load2"
                      @clear="limpiar2()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <BubbleChart 
                      :datos="rosVsRoiSucData"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- Sección GMROS vs GMROI Locales -->
        <v-expansion-panels v-model="panel2" class="pt-2">
          <v-expansion-panel readonly>
            <!-- Titulo -->
            <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
              <div>
                <v-icon class="mb-1" left>fas fa-filter</v-icon>
                GMROS vs GMROI Locales
              </div>
              <template v-slot:actions>
                <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                  <v-icon>fas fa-chevron-down</v-icon>
                </v-btn>
              </template>
            </v-expansion-panel-header>
            <!-- Filtros -->
            <v-expansion-panel-content class="pt-1">
              <v-form @submit.prevent="buscarIndicesLoc">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" sm="6" md="6" class="py-2">
                    <v-autocomplete
                      v-model="selectSucursal"
                      label="Sucursal"
                      item-text="sucursal_nombre"
                      :items="sucursalesLocal"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-2">
                    <v-autocomplete
                      v-model="seleccionCanal"
                      label="Canal"
                      item-text="canal_nombre"
                      :items="canales"
                      hide-details
                      outlined
                      dense
                      return-object
                      clearable
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <BtnFiltro
                      nombre="Buscar"
                      icono="fas fa-search"
                      clase="mt-0"
                      :loading="load3"
                      @clear="limpiar3()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="pt-2">
          <v-col cols="12">
            <v-card
              flat
              outlined
            >
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <BubbleChartLocales 
                      :datos="rosVsRoiLocData"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GMROI from './LineChart.vue'
import GMROS from './LineChart.vue'
import GMROL from './LineChart.vue'
import BubbleChart from './BubbleChart.vue'
import BubbleChartLocales from './BubbleChart.vue'
import moment from 'moment'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import { objetoNoVacio, order_list_by_date, randomNumber, roundNumber } from '../../util/utils'
export default {
  data() {
    return {
      panel: 0,
      panel1: 0,
      panel2: 0,
      selectedSucursal: {},
      sucursales: [],
      selectedCanal: {},
      canales: [],
      selectedLocales: [],
      locales: [],
      load: false,
      load2: false,
      load3: false,
      indices: [],
      gmRoiData: {
        labels: [],
        data_sets: [],
        titulo: ''
      },
      gmRosData: {
        labels: [],
        data_sets: [],
        titulo: ''
      },
      gmRolData: {
        labels: [],
        data_sets: [],
        titulo: ''
      },
      rosVsRoiSucData: {
        labels: [],
        data_sets: [],
        id: 'burbujasSucursales'
      },
      rosVsRoiLocData: {
        labels: [],
        data_sets: [],
        id: 'burbujasLocales'
      },
      cantPeriodos: 0,
      encabezadoRoi: [],
      itemsRoi: [],
      encabezadoRos: [],
      itemsRos: [],
      encabezadoRol: [],
      itemsRol: [],
      selectCanal: {},
      sucursalesLocal: [],
      parametros: [],
      datosContribucion: [],
      promEmpresa: {
        promedioGMROS: 0,
        promedioGMROI: 0
      },
      selectSucursal: {},
      seleccionCanal: {},
      indicesLocales: []
    }
  },
  components: {
    BtnFiltro,
    GMROI,
    GMROS,
    GMROL,
    BubbleChart,
    BubbleChartLocales
  },
  created () {
    this.initForm()
  },
  methods: {
    async initForm(){
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('indices/initIndices')
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      this.sucursales = initPeticion.sucursales
      this.canales = initPeticion.canales
      this.sucursalesLocal = initPeticion.sucursales_x_usuario
      this.parametros = initPeticion.parametros
      this.datosContribucion = initPeticion.contribucion_sucursales
      // agrego la opcion TODOS a las sucursales x usuario
      this.sucursalesLocal.push({sucursal_codigo: '0', sucursal_nombre: 'TODOS'})
      // para la sección GMROS vs GMROI Sucursales seteo el canal directa por defecto
      let directa = this.canales.filter(element => element.canal_codigo == 1)
      if (directa.length > 0) this.selectCanal = directa[0]
      // paso a iniciar el gráfico de burbujas para Sucursales
      if (this.datosContribucion.length > 0) this.getGraficoSucursales()
    },
    async getLocales(opcion){
      let sucursal = 0
      let canal = 0
      if (opcion == 1 && (this.selectedSucursal == null || this.selectedSucursal == undefined)) return
      if (opcion == 1){
        if (!objetoNoVacio(this.selectedSucursal)){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar la sucursal para empezar la búsqueda.',
            color: 'info',
          })
          return
        }
        sucursal = this.selectedSucursal.sucursal_codigo
        if (objetoNoVacio(this.selectedCanal)) canal = this.selectedCanal.canal_codigo
        this.locales = []
      }
      this.$store.state.loading = true
      let localesPeticion = await this.$store.dispatch('indices/getLocales', {
        sucursal_codigo: sucursal,
        canal_codigo: canal
      })
      this.$store.state.loading = false
      if (localesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: localesPeticion.msj,
          color: 'error',
        })
        return
      }
      if (opcion == 1) this.locales = localesPeticion.locales
    },
    limpiar(){
      this.selectedSucursal = {}
      this.selectedCanal = {}
      this.selectedLocales = []
      this.locales = []
    },
    limpiar2(){
      this.selectCanal = {}
    },
    limpiar3(){
      this.selectSucursal = {}
      this.seleccionCanal = {}
    },
    async buscar(){
      if (!objetoNoVacio(this.selectedSucursal)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar la sucursal para empezar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.indices = []
      let canal = 0
      if (objetoNoVacio(this.selectedCanal)) canal = this.selectedCanal.canal_codigo
      this.load = true
      let indicesPeticion = await this.$store.dispatch('indices/getIndices', {
        sucursal_codigo: this.selectedSucursal.sucursal_codigo,
        canal_codigo: canal,
        locales: this.selectedLocales
      })
      this.load = false
      if (indicesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: indicesPeticion.msj,
          color: 'error',
        })
        return
      }
      this.indices = indicesPeticion.indices
      for (let id in this.indices){
        this.indices[id].periodo = moment(this.indices[id].periodo).format("DD/MM/YYYY")
      }
      this.controlFechas()
    },
    async buscarIndicesSuc(){
      if (!objetoNoVacio(this.selectCanal)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar el canal para empezar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.datosContribucion = []
      this.load2 = true
      let indicesPeticion = await this.$store.dispatch('indices/getIndicesSuc', {canal_codigo: this.selectCanal.canal_codigo})
      this.load2 = false
      if (indicesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: indicesPeticion.msj,
          color: 'error',
        })
        return
      }
      this.datosContribucion = indicesPeticion.contribucion_sucursales
      if (this.datosContribucion.length > 0){
        this.rosVsRoiSucData.labels = []
        this.rosVsRoiSucData.data_sets = []
        this.getGraficoSucursales()
      }
    },
    async buscarIndicesLoc(){
      if (!objetoNoVacio(this.selectSucursal) || !objetoNoVacio(this.seleccionCanal)){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar la sucursal y el canal para empezar la búsqueda.',
          color: 'info',
        })
        return
      }
      this.indicesLocales = []
      this.load3 = true
      let indicesPeticion = await this.$store.dispatch('indices/getIndicesLoc', {
        sucursal_codigo: this.selectSucursal.sucursal_codigo,
        canal_codigo: this.seleccionCanal.canal_codigo
      })
      this.load3 = false
      if (indicesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: indicesPeticion.msj,
          color: 'error',
        })
        return
      }
      let datosPromedio = []
      this.indicesLocales = indicesPeticion.contribucion_locales
      if (this.selectSucursal.sucursal_codigo != 0) datosPromedio = indicesPeticion.contribucion_promedio
      else datosPromedio = indicesPeticion.contribucion_locales
      // paso a generar el grafico de burbujas para locales
      this.generarBubblesLocales(datosPromedio)
    },
    completarPeriodos(minPeriodo, maxPeriodo){
      // completo los periodos entre dos periodos
      let separarMin = minPeriodo.split("/")
      let fechaMin = minPeriodo
      let listadoPeriodos = []
      do{
          listadoPeriodos.push(fechaMin)
          if (parseInt(separarMin[1]) === 12){
              separarMin[1] = '01'
              separarMin[2] = (parseInt(separarMin[2]) + 1).toString()
          }else{
              separarMin[1] = String((parseInt(separarMin[1]) + 1).toString()).padStart(2, '0')
          }
          fechaMin = separarMin[0].toString() + '/' + separarMin[1].toString() + '/' + separarMin[2].toString()
      }while (moment(fechaMin, 'DD/MM/YYYY' ) <= moment(maxPeriodo, 'DD/MM/YYYY' ))
      return listadoPeriodos
    },
    controlFechas(){
      // ordeno mi listado por periodo
      order_list_by_date(this.indices, 'periodo', 'asc')
      // obtengo el mínimo y máximo periodo
      let minPeriodo = this.indices[0].periodo
      let maxPeriodo = this.indices[this.indices.length - 1].periodo
      let periodosCompleto = this.completarPeriodos(minPeriodo, maxPeriodo)
      // asigno mis labels para el eje x
      this.gmRoiData.labels = periodosCompleto
      this.gmRosData.labels = periodosCompleto
      this.gmRolData.labels = periodosCompleto
      // armo los dataSet
      this.generarGMROI(periodosCompleto)
      this.generarGMROS(periodosCompleto)
      this.generarGMROL(periodosCompleto)
      // tratamiento de los data table
      this.iniciarDataTables(periodosCompleto)
    },
    generarGMROI(periodos){
      this.gmRoiData.data_sets = []
      if (this.selectedLocales.length > 0){
        // solo busco locales seleccionados
        for (let id in this.selectedLocales){
          // busco a ver si tengo resultados para el local actual
          let listado = this.indices.filter(element => element.local_codigo == this.selectedLocales[id].local_codigo)
          if (listado.length > 0){
            // inicializo variables
            let label = listado[0].local
            let data = []
            // colores, genero randoms de 0 a 255
            let borderColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            // recorro los periodos para completar los dataSet
            for (let jd in periodos){
              // busco si existe un valor para el periodo actual
              let existeValor = listado.filter(element => element.periodo == periodos[jd])
              if (existeValor.length == 1){
                // guardo el valor
                data.push(roundNumber(listado[listado.indexOf(existeValor[0])].gmroi, 2))
              }else{
                // no tiene valor para ese periodo
                data.push(0)
              }
            }
            // asigno
            let dataSet = {
              label: label,
              data: data,
              backgroundColor: "transparent",
              borderColor: borderColor,
              pointBackgroundColor: pointBackgroundColor
            }
            this.gmRoiData.data_sets.push(dataSet)
          }
        }
      }else{
        // buscas todos los locales de la sucursal
        for (let id in this.locales){
          // busco a ver si tengo resultados para el local actual
          let listado = this.indices.filter(element => element.local_codigo == this.locales[id].local_codigo)
          if (listado.length > 0){
            // inicializo variables
            let label = listado[0].local
            let data = []
            // colores, genero randoms de 0 a 255
            let borderColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            // recorro los periodos para completar los dataSet
            for (let jd in periodos){
              // busco si existe un valor para el periodo actual
              let existeValor = listado.filter(element => element.periodo == periodos[jd])
              if (existeValor.length == 1){
                // guardo el valor
                data.push(roundNumber(listado[listado.indexOf(existeValor[0])].gmroi, 2))
              }else{
                // no tiene valor para ese periodo
                data.push(0)
              }
            }
            // asigno
            let dataSet = {
              label: label,
              data: data,
              backgroundColor: "transparent",
              borderColor: borderColor,
              pointBackgroundColor: pointBackgroundColor
            }
            this.gmRoiData.data_sets.push(dataSet)
          }
        }
      }
    },
    generarGMROS(periodos){
      this.gmRosData.data_sets = []
      if (this.selectedLocales.length > 0){
        // solo busco locales seleccionados
        for (let id in this.selectedLocales){
          // busco a ver si tengo resultados para el local actual
          let listado = this.indices.filter(element => element.local_codigo == this.selectedLocales[id].local_codigo)
          if (listado.length > 0){
            // inicializo variables
            let label = listado[0].local
            let data = []
            // colores, genero randoms de 0 a 255
            let borderColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            // recorro los periodos para completar los dataSet
            for (let jd in periodos){
              // busco si existe un valor para el periodo actual
              let existeValor = listado.filter(element => element.periodo == periodos[jd])
              if (existeValor.length == 1){
                // guardo el valor
                data.push(roundNumber(listado[listado.indexOf(existeValor[0])].gmros, 2))
              }else{
                // no tiene valor para ese periodo
                data.push(0)
              }
            }
            // asigno
            let dataSet = {
              label: label,
              data: data,
              backgroundColor: "transparent",
              borderColor: borderColor,
              pointBackgroundColor: pointBackgroundColor
            }
            this.gmRosData.data_sets.push(dataSet)
          }
        }
      }else{
        // buscas todos los locales de la sucursal
        for (let id in this.locales){
          // busco a ver si tengo resultados para el local actual
          let listado = this.indices.filter(element => element.local_codigo == this.locales[id].local_codigo)
          if (listado.length > 0){
            // inicializo variables
            let label = listado[0].local
            let data = []
            // colores, genero randoms de 0 a 255
            let borderColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            // recorro los periodos para completar los dataSet
            for (let jd in periodos){
              // busco si existe un valor para el periodo actual
              let existeValor = listado.filter(element => element.periodo == periodos[jd])
              if (existeValor.length == 1){
                // guardo el valor
                data.push(roundNumber(listado[listado.indexOf(existeValor[0])].gmros, 2))
              }else{
                // no tiene valor para ese periodo
                data.push(0)
              }
            }
            // asigno
            let dataSet = {
              label: label,
              data: data,
              backgroundColor: "transparent",
              borderColor: borderColor,
              pointBackgroundColor: pointBackgroundColor
            }
            this.gmRosData.data_sets.push(dataSet)
          }
        }
      }
    },
    generarGMROL(periodos){
      this.gmRolData.data_sets = []
      if (this.selectedLocales.length > 0){
        // solo busco locales seleccionados
        for (let id in this.selectedLocales){
          // busco a ver si tengo resultados para el local actual
          let listado = this.indices.filter(element => element.local_codigo == this.selectedLocales[id].local_codigo)
          if (listado.length > 0){
            // inicializo variables
            let label = listado[0].local
            let data = []
            // colores, genero randoms de 0 a 255
            let borderColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            // recorro los periodos para completar los dataSet
            for (let jd in periodos){
              // busco si existe un valor para el periodo actual
              let existeValor = listado.filter(element => element.periodo == periodos[jd])
              if (existeValor.length == 1){
                // guardo el valor
                data.push(roundNumber(listado[listado.indexOf(existeValor[0])].gmrol, 2))
              }else{
                // no tiene valor para ese periodo
                data.push(0)
              }
            }
            // asigno
            let dataSet = {
              label: label,
              data: data,
              backgroundColor: "transparent",
              borderColor: borderColor,
              pointBackgroundColor: pointBackgroundColor
            }
            this.gmRolData.data_sets.push(dataSet)
          }
        }
      }else{
        // buscas todos los locales de la sucursal
        for (let id in this.locales){
          // busco a ver si tengo resultados para el local actual
          let listado = this.indices.filter(element => element.local_codigo == this.locales[id].local_codigo)
          if (listado.length > 0){
            // inicializo variables
            let label = listado[0].local
            let data = []
            // colores, genero randoms de 0 a 255
            let borderColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
            // recorro los periodos para completar los dataSet
            for (let jd in periodos){
              // busco si existe un valor para el periodo actual
              let existeValor = listado.filter(element => element.periodo == periodos[jd])
              if (existeValor.length == 1){
                // guardo el valor
                data.push(roundNumber(listado[listado.indexOf(existeValor[0])].gmrol, 2))
              }else{
                // no tiene valor para ese periodo
                data.push(0)
              }
            }
            // asigno
            let dataSet = {
              label: label,
              data: data,
              backgroundColor: "transparent",
              borderColor: borderColor,
              pointBackgroundColor: pointBackgroundColor
            }
            this.gmRolData.data_sets.push(dataSet)
          }
        }
      }
    },
    iniciarDataTables(periodos){
      this.cantPeriodos = periodos.length
      this.encabezadoRoi = []
      this.itemsRoi = []
      this.encabezadoRos = []
      this.itemsRos = []
      this.encabezadoRol = []
      this.itemsRol = []
      // agrego el local como primera columna
      let colLocal = {
        text: 'Locales', 
        align:'center', 
        sortable: true,
        value: 'local_nombre'
      }
      this.encabezadoRoi.push(colLocal)
      this.encabezadoRos.push(colLocal)
      this.encabezadoRol.push(colLocal)
      // armar encabezados
      for (let id in periodos){
        let unEncabezado = {
          text: periodos[id], 
          align:'center', 
          sortable: true,
          value: 'col' + id
        }
        this.encabezadoRoi.push(unEncabezado)
        this.encabezadoRos.push(unEncabezado)
        this.encabezadoRol.push(unEncabezado)
      }
      // armar detalles
      if (this.selectedLocales.length > 0){
        // armo teniendo en cuenta la seleccion del usuario
        for (let id in this.selectedLocales){
          // obtengo un listado de los indices correspondientes al local
          let unListado = this.indices.filter(element => element.local_codigo == this.selectedLocales[id].local_codigo)
          if (unListado.length > 0){
            let localNombre = unListado[0].local
            // defino una fila
            let unDetalleRoi = {}
            let unDetalleRos = {}
            let unDetalleRol = {}
            // asigno a mi objeto el nombre del local
            unDetalleRoi.local_nombre = localNombre
            unDetalleRos.local_nombre = localNombre
            unDetalleRol.local_nombre = localNombre
            // tengo valores, recorro los periodos ordenados para armar los detalles del data table
            for (let jd in periodos){
              // veo si tengo un valor de indices para el periodo que estamos tomando en el ciclo
              let unValorLocal = unListado.filter(element => element.periodo == periodos[jd])
              if (unValorLocal.length > 0){
                // tengo un valor para el periodo, tomo los datos de unValorLocal
                unDetalleRoi['col' + jd] = roundNumber(unValorLocal[0].gmroi, 2)
                unDetalleRos['col' + jd] = roundNumber(unValorLocal[0].gmros, 2)
                unDetalleRol['col' + jd] = roundNumber(unValorLocal[0].gmrol, 2)
              }else{
                // no tengo valor para el periodo, relleno con 0
                unDetalleRoi['col' + jd] = 0
                unDetalleRos['col' + jd] = 0
                unDetalleRol['col' + jd] = 0
              }
            }
            // terminada de armar la fila, la agrego a mis items
            this.itemsRoi.push(unDetalleRoi)
            this.itemsRos.push(unDetalleRos)
            this.itemsRol.push(unDetalleRol)
          }
        }
      }else{
        // armo con todos los locales de la sucursal
        for (let id in this.locales){
          // obtengo un listado de los indices correspondientes al local
          let unListado = this.indices.filter(element => element.local_codigo == this.locales[id].local_codigo)
          if (unListado.length > 0){
            // nombre del local
            let localNombre = unListado[0].local
            // defino una fila
            let unDetalleRoi = {}
            let unDetalleRos = {}
            let unDetalleRol = {}
            // asigno a mi objeto el nombre del local
            unDetalleRoi.local_nombre = localNombre
            unDetalleRos.local_nombre = localNombre
            unDetalleRol.local_nombre = localNombre
            // tengo valores, recorro los periodos ordenados para armar los detalles del data table
            for (let jd in periodos){
              // veo si tengo un valor de indices para el periodo que estamos tomando en el ciclo
              let unValorLocal = unListado.filter(element => element.periodo == periodos[jd])
              if (unValorLocal.length > 0){
                // tengo un valor para el periodo, tomo los datos de unValorLocal
                unDetalleRoi['col' + jd] = roundNumber(unValorLocal[0].gmroi, 2)
                unDetalleRos['col' + jd] = roundNumber(unValorLocal[0].gmros, 2)
                unDetalleRol['col' + jd] = roundNumber(unValorLocal[0].gmrol, 2)
              }else{
                // no tengo valor para el periodo, relleno con 0
                unDetalleRoi['col' + jd] = 0
                unDetalleRos['col' + jd] = 0
                unDetalleRol['col' + jd] = 0
              }
            }
            // terminada de armar la fila, la agrego a mis items
            this.itemsRoi.push(unDetalleRoi)
            this.itemsRos.push(unDetalleRos)
            this.itemsRol.push(unDetalleRol)
          }
        }
      }
    },
    getGraficoSucursales(){
      // obtengo los promedios de GMROS y GMROI
      let promedios = this.calcularPromedios(this.datosContribucion)
      // ordeno ascendente GMROS
      let listadoGMROS = this.ordenarGMROS(this.datosContribucion, {sucursalCodigo: 0, sucursalNombre: 'PROMEDIO', gmroi: 0, gmros: promedios.promedioGMROS})
      // ordeno ascendente GMROI
      let listadoGMROI = this.ordenarGMROI(this.datosContribucion, {sucursalCodigo: 0, sucursalNombre: 'PROMEDIO', gmroi: promedios.promedioGMROI, gmros: 0})
      // obtengo mis labels
      this.rosVsRoiSucData.labels = listadoGMROI.map(function (task, index, array){ return task.gmroi })
      // genero las burbujas de Sucursales
      let dataSetsBubbles = this.generarBubblesSucursales(this.datosContribucion)
      for (let id in dataSetsBubbles){
        this.rosVsRoiSucData.data_sets.push(dataSetsBubbles[id])
      }
      // armo la linea promedio para gmros, y = promedioGMROS para todo x
      let dataSetLineGMROS = this.generarGraficoGMROS(listadoGMROI, promedios, 1, null)
      // lo agrego al dataSet
      this.rosVsRoiSucData.data_sets.push(dataSetLineGMROS)
      // armo la linea promedio para gmroi, x = promedioGMROI para todo y
      let dataSetLineGMROI = this.generarGraficoGMROI(listadoGMROS, promedios, 1, null)
      // lo agrego al dataSet
      this.rosVsRoiSucData.data_sets.push(dataSetLineGMROI)
    },
    calcularPromedios(listado){
      let acumuGMROS = 0
      let acumuGMROI = 0
      for (let id in listado){
        acumuGMROS = parseFloat(acumuGMROS) + parseFloat(listado[id].gmros)
        acumuGMROI = parseFloat(acumuGMROI) + parseFloat(listado[id].gmroi)
      }
      let promedioGMROS = parseFloat(parseFloat(acumuGMROS)/listado.length).toFixed(2)
      let promedioGMROI = parseFloat(parseFloat(acumuGMROI)/listado.length).toFixed(2)
      return {
        promedioGMROS: promedioGMROS,
        promedioGMROI: promedioGMROI
      }
    },
    ordenarGMROS(listado, promedio){
      let listadoOrdenado = []
      for (let id in listado){
        listadoOrdenado.push(listado[id])
      }
      listadoOrdenado.push(promedio)
      // intento predecir el maximo valor del ROS en la grafica
      let maximoROS = this.predecirLimite(2, listadoOrdenado)
      // inserto el maximo en el ROS
      listadoOrdenado.push({sucursalCodigo: 0, sucursalNombre: 'PROMEDIO_NUEVO', gmroi: 0, gmros: 0})
      listadoOrdenado.push({sucursalCodigo: 0, sucursalNombre: 'MAXIMO_ROS', gmroi: 0, gmros: maximoROS})
      listadoOrdenado.sort(function(a,b){
        if (parseFloat(a.gmros) < parseFloat(b.gmros)) {
          return -1;
        }
        if (parseFloat(a.gmros) > parseFloat(b.gmros)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      return listadoOrdenado
    },
    ordenarGMROI(listado, promedio){
      let listadoOrdenado = []
      for (let id in listado){
        listadoOrdenado.push(listado[id])
      }
      listadoOrdenado.push(promedio)
      listadoOrdenado.push({sucursalCodigo: 0, sucursalNombre: 'PROMEDIO_NUEVO', gmroi: 0, gmros: 0})
      // intento predecir el maximo valor del ROI en la grafica
      let maxROI = this.predecirLimite(1, listadoOrdenado)
      // inserto el maximo en el ROI
      listadoOrdenado.push({sucursalCodigo: 0, sucursalNombre: 'MAXIMO_ROI', gmroi: maxROI, gmros: 0})
      listadoOrdenado.sort(function(a,b){
        if (parseFloat(a.gmroi) < parseFloat(b.gmroi)) {
          return -1;
        }
        if (parseFloat(a.gmroi) > parseFloat(b.gmroi)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      return listadoOrdenado
    },
    generarBubblesSucursales(listado){
      let listadoOrdenado = []
      for (let id in listado){
        listadoOrdenado.push(listado[id])
      }
      listadoOrdenado.sort(function(a,b){
        if (parseFloat(a.gmroi) < parseFloat(b.gmroi)) {
          return -1;
        }
        if (parseFloat(a.gmroi) > parseFloat(b.gmroi)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      let bubblesSucursales = []
      for (let id in listadoOrdenado){
        let backgroundColor = 'rgba(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255)  + ', ' + randomNumber(0, 255) + ', ' + '0.5' + ')'
        let unaBurbuja = {
          label: listadoOrdenado[id].sucursal,
          type: "bubble",
          data: [
            {
              x: (parseFloat(listadoOrdenado[id].gmroi).toFixed(2)).toString(),
              y: (parseFloat(listadoOrdenado[id].gmros).toFixed(2)).toString(),
              r: (parseFloat(listadoOrdenado[id].radio).toFixed(2)).toString(),
            }
          ],
          backgroundColor: backgroundColor,
          borderWidth: 3
        }
        bubblesSucursales.push(unaBurbuja)
      }
      return bubblesSucursales
    },
    generarGraficoGMROS(listadoGMROI, promedios, opcion, listadoROI){
      // armo la linea promedio para gmros, y = promedioGMROS para todo x
      let borderColor = ''
      let tituloDataSet = ''
      if (parseInt(opcion) === 1){
        borderColor = 'rgb(255, 87, 51)'
        tituloDataSet = "Promedio GMROS Empresa: " + promedios.promedioGMROS
      }else{
        borderColor = 'rgb(44, 211, 21)'
        tituloDataSet = "Promedio GMROS Sucursal: " + promedios.promedioGMROS
      }
      let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
      let dataGMROS = []
      // solo tomo el minimo y el maximo
      let unPunto = {
        x: roundNumber(listadoGMROI[0].gmroi, 4),
        y: roundNumber(promedios.promedioGMROS, 4)
      }
      dataGMROS.push(unPunto)
      let otroPunto = {}
      if (parseInt(opcion) === 1){
        // quien es el mayor limite?
        let limite = 0
        if (listadoROI != null){
          if (parseFloat(listadoGMROI[listadoGMROI.length-1].gmroi) < parseFloat(listadoROI[listadoROI.length-1].gmroi)){
            limite = listadoGMROI[listadoGMROI.length-1].gmroi
          }else{
            limite = listadoROI[listadoROI.length-1].gmroi
          }
          otroPunto = {
            x: roundNumber(limite, 4),
            y: roundNumber(promedios.promedioGMROS, 4)
          }
        }else{
          otroPunto = {
            x: roundNumber(listadoGMROI[listadoGMROI.length-1].gmroi, 4),
            y: roundNumber(promedios.promedioGMROS, 4)
          }
        }
      }else{
        // quien es el mayor limite?
        let limite = 0
        if (parseFloat(listadoGMROI[listadoGMROI.length-1].gmroi) < parseFloat(listadoROI[listadoROI.length-1].gmroi)){
          limite = listadoGMROI[listadoGMROI.length-1].gmroi
        }else{
          limite = listadoROI[listadoROI.length-1].gmroi
        }
        otroPunto = {
          x: roundNumber(limite, 4),
          y: roundNumber(promedios.promedioGMROS, 4)
        }
      }
      dataGMROS.push(otroPunto)
      let dataSetLineGMROS = {
        label: tituloDataSet,
        type: 'line',
        data: dataGMROS,
        borderDash: [10,5],
        backgroundColor: "transparent",
        borderColor: borderColor,
        pointBackgroundColor: pointBackgroundColor
      }
      return dataSetLineGMROS
    },
    generarGraficoGMROI(listadoGMROS, promedios, opcion, listadoROS){
      // armo la linea promedio para gmroi, x = promedioGMROI para todo y
      let borderColor = ''
      let tituloDataSet = ''
      if (parseInt(opcion) === 1){
        borderColor = 'rgb(255, 87, 51)'
        tituloDataSet = "Promedio GMROI Empresa: " + promedios.promedioGMROI
      }else{
        borderColor = 'rgb(44, 211, 21)'
        tituloDataSet = "Promedio GMROI Sucursal: " + promedios.promedioGMROI
      }
      let pointBackgroundColor = 'rgb(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ')'
      let dataGMROI = []
      // solo tomo el minimo y el maximo
      let unPunto = {
        x: roundNumber(promedios.promedioGMROI, 4),
        y: roundNumber(listadoGMROS[0].gmros, 4)
      }
      dataGMROI.push(unPunto)
      let otroPunto = {}
      if (parseInt(opcion) === 1){
        if (listadoROS != null){
          // quien es el mayor limite?
          let limite = 0
          if (parseFloat(listadoGMROS[listadoGMROS.length-1].gmros) < parseFloat(listadoROS[listadoROS.length-1].gmros)){
            limite = listadoGMROS[listadoGMROS.length-1].gmros
          }else{
            limite = listadoROS[listadoROS.length-1].gmros
          }
          // mi limite ROS supera el promedio de la empresa ROS?
          if (parseFloat(limite) < parseFloat(this.promEmpresa.promedioGMROS)){
            // recalculo mi limite
            limite = this.predecirLimite(2, [{gmros: this.promEmpresa.promedioGMROS, gmroi: this.promEmpresa.promedioGMROI}])
          }
          otroPunto = {
            x: roundNumber(promedios.promedioGMROI, 4),
            y: roundNumber(limite, 4)
          }
        }else{
          otroPunto = {
            x: roundNumber(promedios.promedioGMROI, 4),
            y: roundNumber(listadoGMROS[listadoGMROS.length-1].gmros, 4)
          }
        }
      }else{
        // quien es el mayor limite?
        let limite = 0
        if (parseFloat(listadoGMROS[listadoGMROS.length-1].gmros) < parseFloat(listadoROS[listadoROS.length-1].gmros)){
          limite = listadoGMROS[listadoGMROS.length-1].gmros
        }else{
          limite = listadoROS[listadoROS.length-1].gmros
        }
        // mi limite ROS supera el promedio de la empresa ROS?
        if (parseFloat(limite) < parseFloat(this.promEmpresa.promedioGMROS)){
          // recalculo mi limite
          limite = this.predecirLimite(2, [{gmros: this.promEmpresa.promedioGMROS, gmroi: this.promEmpresa.promedioGMROI}])
        }
        otroPunto = {
          x: roundNumber(promedios.promedioGMROI, 4),
          y: roundNumber(limite, 4)
        }
      }
      dataGMROI.push(otroPunto)
      let dataSetLineGMROI = {
        label: tituloDataSet, // titulo de referencia
        type: 'line', // tipo de grafico
        data: dataGMROI, // coordenadas que forman el grafico
        borderDash: [10,5], // indica lineas puntadas
        backgroundColor: "transparent", // sin color de relleno
        borderColor: borderColor, // color de la linea
        pointBackgroundColor: pointBackgroundColor // color del punto
      }
      return dataSetLineGMROI
    },
    predecirLimite(opcion, listado){
      if (parseInt(opcion) === 1){
        // predecir ROI
        let lisOrdenado = listado
        lisOrdenado.sort(function(a,b){
          if (parseFloat(a.gmroi) < parseFloat(b.gmroi)) {
            return -1;
          }
          if (parseFloat(a.gmroi) > parseFloat(b.gmroi)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
        // obtengo el maximo valor
        let maximo = lisOrdenado[lisOrdenado.length-1].gmroi
        let limite = 0
        if (parseInt(maximo) >= 10){
          // para armar el maximo, obtengo el primer digito de la izquierda y le agrego tantos ceros como cifras tenga despues de ese primer digito
          let cifras = ((parseInt(maximo)).toString()).length
          let ceros = cifras - 1
          limite = (parseInt(maximo[0]) + 1)
          for (let i = 1; i<=parseInt(ceros); i++){
            limite = limite + '0'
          }
        }else{
          limite = parseInt(roundNumber(maximo, 4)) + 1
        }
        return limite
      }else{
        // predecir ROS
        let lisOrdenado = listado
        lisOrdenado.sort(function(a,b){
          if (parseFloat(a.gmros) < parseFloat(b.gmros)) {
            return -1;
          }
          if (parseFloat(a.gmros) > parseFloat(b.gmros)) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
        // obtengo el maximo valor
        let maximo = lisOrdenado[lisOrdenado.length-1].gmros
        // para armar el maximo, obtengo el primer digito de la izquierda y le agrego tantos ceros como cifras tenga despues de ese primer digito
        let cifras = ((parseInt(maximo)).toString()).length
        let ceros = cifras - 1
        let limite = (parseInt(maximo.toString()[0]) + 1)
        for (let i = 1; i<=parseInt(ceros); i++){
          limite = limite + '0'
        }
        return limite
      }
    },
    generarBubblesLocales(datosPromedio){
      // limpio arrays
      this.rosVsRoiLocData.labels = []
      this.rosVsRoiLocData.data_sets = []
      // obtengo el promedio de GMROS y GMROI
      let promedios = this.calcularPromedios(this.indicesLocales)
      // obtengo el promedio de GMROS y GMROI de la EMPRSA
      let promediosEmpresa = this.calcularPromedios(datosPromedio)
      // guardo los promedios
      this.promEmpresa = promediosEmpresa
      // ordeno ascendente GMROS
      let listadoGMROS = this.ordenarGMROS(this.indicesLocales, {sucursalCodigo: 0, sucursalNombre: 'PROMEDIO', gmroi: 0, gmros: promedios.promedioGMROS})
      // ordeno ascendente GMROI
      let listadoGMROI = this.ordenarGMROI(this.indicesLocales, {sucursalCodigo: 0, sucursalNombre: 'PROMEDIO', gmroi: promedios.promedioGMROI, gmros: 0})
      // obtengo mis labels
      this.rosVsRoiLocData.labels = listadoGMROI.map(function (task, index, array){ return task.gmroi })
      // genero las burbujas de Sucursales
      let dataSetsBubbles = this.generarBubblesLoc(this.indicesLocales)
      for (let id in dataSetsBubbles){
        this.rosVsRoiLocData.data_sets.push(dataSetsBubbles[id])
      }
      // ordeno ascendente GMROS de la EMPRSA
      let listadoGMROSEmpresa = this.ordenarGMROS(datosPromedio, {sucursalCodigo: 0, sucursalNombre: 'PROMEDIO', gmroi: 0, gmros: promediosEmpresa.promedioGMROS})
      // ordeno ascendente GMROI de la EMPRSA
      let listadoGMROIEmpresa = this.ordenarGMROI(datosPromedio, {sucursalCodigo: 0, sucursalNombre: 'PROMEDIO', gmroi: promediosEmpresa.promedioGMROI, gmros: 0})
      // coloco los promedios de la empresa?
      if (parseInt(this.selectSucursal.sucursal_codigo) !== 0){
        // armo la linea promedio para gmros de la EMPRSA, y = promedioGMROS para todo x
        let dataSetLineGMROSEmpresa = this.generarGraficoGMROS(listadoGMROIEmpresa, promediosEmpresa, 1, listadoGMROI)
        // lo agrego al dataSet
        this.rosVsRoiLocData.data_sets.push(dataSetLineGMROSEmpresa)
        // armo la linea promedio para gmroi de la EMPRSA, x = promedioGMROI para todo y
        let dataSetLineGMROIEmpresa = this.generarGraficoGMROI(listadoGMROSEmpresa, promediosEmpresa, 1, listadoGMROS)
        // lo agrego al dataSet
        this.rosVsRoiLocData.data_sets.push(dataSetLineGMROIEmpresa)
      }
      // armo la linea promedio para gmros, y = promedioGMROS para todo x
      let dataSetLineGMROS = this.generarGraficoGMROS(listadoGMROI, promedios, 2, listadoGMROIEmpresa)
      // lo agrego al dataSet
      this.rosVsRoiLocData.data_sets.push(dataSetLineGMROS)
      // armo la linea promedio para gmroi, x = promedioGMROI para todo y
      let dataSetLineGMROI = this.generarGraficoGMROI(listadoGMROS, promedios, 2, listadoGMROSEmpresa)
      // lo agrego al dataSet
      this.rosVsRoiLocData.data_sets.push(dataSetLineGMROI)
    },
    generarBubblesLoc(listado){
      let listadoOrdenado = []
      for (let id in listado){
        listadoOrdenado.push(listado[id])
      }
      listadoOrdenado.sort(function(a,b){
        if (parseFloat(a.gmroi) < parseFloat(b.gmroi)) {
          return -1;
        }
        if (parseFloat(a.gmroi) > parseFloat(b.gmroi)) {
          return 1;
        }
        // a must be equal to b
        return 0;
      })
      let bubblesLocales = []
      for (let id in listadoOrdenado){
        let backgroundColor = 'rgba(' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + randomNumber(0, 255) + ', ' + '0.5' + ')'
        let unaBurbuja = {
          label: listadoOrdenado[id].local,
          type: "bubble",
          data: [
            {
              x: (parseFloat(listadoOrdenado[id].gmroi).toFixed(2)).toString(),
              y: (parseFloat(listadoOrdenado[id].gmros).toFixed(2)).toString(),
              r: (parseFloat(listadoOrdenado[id].radio).toFixed(2)).toString(),
            }
          ],
          backgroundColor: backgroundColor,
          borderWidth: 3
        }
        bubblesLocales.push(unaBurbuja)
      }
      return bubblesLocales
    }
  },
  watch: {
    selectedSucursal: function(){
      if (this.selectedSucursal == null || this.selectedSucursal == undefined) this.selectedSucursal = {}
    },
    selectedCanal: function(){
      if (this.selectedCanal == null || this.selectedCanal == undefined) this.selectedCanal = {}
    },
    selectedLocales: function(){
      if (this.selectedLocales == null || this.selectedLocales == undefined) this.selectedLocales = []
    },
    selectCanal: function(){
      if (this.selectCanal == null || this.selectCanal == undefined) this.selectCanal = {}
    },
    seleccionCanal: function(){
      if (this.seleccionCanal == null || this.seleccionCanal == undefined) this.seleccionCanal = {}
    },
    selectSucursal: function(){
      if (this.selectSucursal == null || this.selectSucursal == undefined) this.selectSucursal = {}
    }
  },
}
</script>

<style>

</style>