<template>
  <canvas :id="id"></canvas>
</template>

<script>
import { Chart } from 'chart.js'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    id: {
      get(){
        return this.datos.id
      }
    },
    data: {
      get(){
        return this.datos.data
      }
    },
    options: {
      get(){
        return this.datos.options
      }
    }
  },
  data() {
    return {
      lineChart: null
    }
  },
  mounted () {
    
  },
  methods: {
    executeChart(){
      this.clearChart()
      const canvas = document.getElementById(this.id)
      const ctx = canvas.getContext('2d')
      this.lineChart = new Chart(ctx, {
        type: 'line',
        data: this.data,
        options: this.options
      })
    },
    clearChart(){
      if (this.lineChart != null) this.lineChart.destroy()
    }
  },
  watch: {
    
  }
}
</script>

<style>

</style>