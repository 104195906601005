<template>
  <div>
    <div :class="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? (padre == 0 ? ($vuetify.breakpoint.sm ? 'chart-mobile2' : 'chart-mobile') : 'chart-mobile-padre') : padre == 0 ? 'chart-container' : 'chart-container-padre'">
      <canvas :id="id"></canvas>
    </div>
    <div :id="`legend${id}`"></div>
  </div>
</template>

<script>
/*
  SE DEJA EL MODELO DE DATASET QUE LLEVA CADA GRAFICO
  doug1: {
        id: 'doug1',
        data: {
          datasets: [{
            data: [10, 20, 30, 40],
            backgroundColor: [
              '#41B883', '#E46651', '#00D8FF', '#DD1B16'
            ],
            borderColor: ['rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)']
          }],
          labels: [
            'Red',
            'Yellow',
            'Blue',
            'Orange'
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          cutoutPercentage: 80, // qué tan ajustada será el área de las circunferencias
          title: {
            display: true,
            text: 'Esto es un gráfico de Dona'
          }
        }
      },
      line1: {
        id: 'line1',
        data: {
          datasets: [
            {
              data: [10, 15, 16, 20],
              backgroundColor:"transparent",
              borderColor:"rgb(251, 225, 146)",
              label: 'esto es un ejemplo de label',
              pointBackgroundColor:"rgb(165, 19, 153)"
            }
          ],
          labels: [
            "01/07/2023",
            "01/08/2023",
            "01/09/2023",
            "01/10/2023"
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          title: {
            display: true,
            text: 'Esto es un gráfico de Lineas'
          }
        }
      },
      bar1: {
        id: 'bar1',
        data: {
          datasets: [
            {
              data: [{x: '25/12/2024', y: 15}],
              backgroundColor: ['#41B883'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              label: 'Barra 1',
              barPercentage: 2
            },
            {
              data: [{x: '26/12/2024', y: 20}],
              backgroundColor: ['#E46651'],
              borderColor: 'rgba(54, 162, 235, 1)',
              label: 'Barra 2',
              barPercentage: 2
            },
            {
              data: [{x: '27/12/2024', y: 9}],
              backgroundColor: ['#00D8FF'],
              borderColor: 'rgba(255, 206, 86, 1)',
              label: 'Barra 3',
              barPercentage: 2
            },
            {
              data: [{x: '28/12/2024', y: 21}],
              backgroundColor: ['#DD1B16'],
              borderColor: 'rgba(75, 192, 192, 1)',
              label: 'Barra 4',
              barPercentage: 2
            },
            {
              data: [{x: '29/12/2024', y: 31}],
              backgroundColor: ['#00D8FF'],
              borderColor: 'rgba(255, 206, 86, 1)',
              label: 'Barra 5',
              barPercentage: 2
            }
          ],
          labels: [
            '25/12/2024',
            '26/12/2024',
            '27/12/2024',
            '28/12/2024',
            '29/12/2024'
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          title: {
            display: true,
            text: 'Esto es un gráfico de Barra Vertical'
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'eje Y'
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'eje X'
              }
            }]
          }
        },
        tipo: 'bar'
      },
      bar2: {
        id: 'bar2',
        data: {
          datasets: [
            {
              data: [{x: 15, y: '25/12/2024'}],
              backgroundColor: ['#41B883'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              label: 'Barra 1',
              barPercentage: 5
            },
            {
              data: [{x: 20, y: '26/12/2024'}],
              backgroundColor: ['#E46651'],
              borderColor: ['rgba(54, 162, 235, 1)'],
              label: 'Barra 2',
              barPercentage: 5
            },
            {
              data: [{x: 9, y: '27/12/2024'}],
              backgroundColor: ['#00D8FF'],
              borderColor: ['rgba(255, 206, 86, 1)'],
              label: 'Barra 3',
              barPercentage: 5
            },
            {
              data: [{x: 21, y: '28/12/2024'}],
              backgroundColor: ['#DD1B16'],
              borderColor: ['rgba(75, 192, 192, 1)'],
              label: 'Barra 4',
              barPercentage: 5
            },
            {
              data: [{x: 31, y: '29/12/2024'}],
              backgroundColor: ['#00D8FF'],
              borderColor: ['rgba(255, 206, 86, 1)'],
              label: 'Barra 5',
              barPercentage: 5
            }
          ],
          labels: [
            // 15,
            // 20,
            // 9,
            // 21,
            // 31,
            '25/12/2024',
            '26/12/2024',
            '27/12/2024',
            '28/12/2024',
            '29/12/2024'
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          title: {
            display: true,
            text: 'Esto es un gráfico de Barra Horizontal'
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'eje X'
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'eje Y'
              }
            }]
          }
        },
        tipo: 'horizontalBar'
      },
      polar1: {
        id: 'polar1',
        data: {
          datasets: [{
            data: [15, 20, 30, 25],
            backgroundColor: ['rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)']
          }],
          labels: ['Red', 'Yellow', 'Blue', 'Orange']
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1, // controla el tamaño del gráfico
          title: {
            display: true,
            text: 'Esto es un gráfico Polar Area'
          },
          scale: {
            gridLines: {
              display: true,
              circular: true,
              color: 'rgba(98, 231, 155, 0.8)'
            }
          }
        }
      },
      burbuja1: {
        id: 'burbuja1',
        data: {
          datasets: [{
            data: [{x: 21, y: 10, r: 10}],
            backgroundColor: ['rgba(255, 99, 132, 1)'],
            borderColor: ['rgba(255, 206, 86, 1)'],
            label: 'Una Burbuja'
          },{
            data: [{x: 10, y: 15, r: 40}],
            backgroundColor: ['rgba(54, 162, 235, 1)'],
            borderColor: ['rgba(75, 192, 192, 1)'],
            label: 'Otra Burbuja'
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          title: {
            display: true,
            text: 'Esto es un gráfico de Burbujas'
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'eje Y'
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'eje X'
              }
            }]
          }
        }
      },
      area1: {
        id: 'area1',
        data: {
          datasets: [
            {
              data: [10, 15, 16, 20],
              backgroundColor:"rgb(165, 19, 153)",
              borderColor:"rgb(251, 225, 146)",
              label: 'esto es un ejemplo de area',
              pointBackgroundColor:"rgb(165, 19, 153)"
            },
            {
              data: [8, 23, 13, 17],
              backgroundColor:"rgb(251, 225, 146)",
              borderColor:"rgb(251, 225, 146)",
              label: 'esto es otro ejemplo de area',
              pointBackgroundColor:"rgb(165, 19, 153)"
            }
          ],
          labels: [
            "01/07/2023",
            "01/08/2023",
            "01/09/2023",
            "01/10/2023"
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          title: {
            display: true,
            text: 'Esto es un gráfico de Area'
          },
          scales: {
            yAxes: [{
              stacked: true
            }]
          }
        }
      }
*/
import { Chart } from 'chart.js'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    id: {
      get(){
        return this.datos.id
      }
    },
    data: {
      get(){
        return this.datos.data
      }
    },
    options: {
      get(){
        return this.datos.options
      }
    },
    padre: {
      get(){
        return this.datos.padre
      }
    }
  },
  components: {
    
  },
  data() {
    return {
      dougChart: null
    }
  },
  methods: {
    executeChart(){
      this.clearChart()
      const canvas = document.getElementById(this.id)
      const ctx = canvas.getContext('2d')
      this.dougChart = new Chart(ctx, {
        type: 'doughnut',
        data: this.data,
        options: this.options
      })
      if (this.options.legend.display == false) document.getElementById(`legend${this.id}`).innerHTML = this.dougChart.generateLegend()
    },
    clearChart(){
      if (this.dougChart != null) this.dougChart.destroy()
    }
  },
  mounted () {
    Chart.pluginService.register({
      beforeDraw: function(chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          var ctx = chart.chart.ctx;

          // Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var maxFontSize = centerConfig.maxFontSize || 75;
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = (chart.innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          var minFontSize = centerConfig.maxFontSize || 75;
          var lineHeight = centerConfig.lineHeight || 25;
          var wrapText = false;

          if (minFontSize === undefined) {
            minFontSize = 20;
          }

          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }

          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }

          var words = txt.split(' ');
          var line = '';
          var lines = [];

          // Break words up into multiple lines if necessary
          for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }

          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;

          for (var n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          ctx.fillText(line, centerX, centerY);
        }
      }
    })
  },
  watch: {
    
  }
}
</script>

<style scoped>
  .chart-container {
    position: relative;
    margin: auto;
    height:12vh
  }
  .chart-container-padre {
    position: relative;
    margin: auto;
    height:22vh
  }
  .chart-mobile {
    position: relative;
    margin: auto;
    max-height: 10vh
  }
  .chart-mobile2{
    position: relative;
    margin: auto;
    max-height: 18vh
  }
  .chart-mobile-padre {
    position: relative;
    margin: auto;
    max-height:40vh
  }
</style>