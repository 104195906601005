<template>
  <v-row class="px-1 py-2">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="4" md="3" class="py-1">
                  Región
                  <v-autocomplete
                    v-model="filtro.regiones"
                    tabindex="1"
                    item-text="nombre"
                    item-value="codigo"
                    :items="regiones"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @change="filtro_sucursales_cob = []; filtro_sucursales_inm = []"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubros"
                    tabindex="1"
                    item-text="nombre"
                    item-value="codigo"
                    :items="rubros"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                    @change="get_categorias(); filtro.categorias = []"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="5" class="py-1">
                  Categoría
                  <v-autocomplete
                    v-model="filtro.categorias"
                    tabindex="1"
                    item-text="nombre"
                    item-value="codigo"
                    :items="categorias_filtradas"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="py-1">
                  Tipo depósito
                  <v-autocomplete
                    v-model="filtro.tipos_deposito"
                    tabindex="1"
                    item-text="nombre"
                    item-value="id"
                    :items="tipos_deposito"
                    deletable-chips
                    hide-details
                    small-chips
                    clearable
                    multiple
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  nombre="Obtener indices"
                  icono="fas fa-chart-line"
                  clase="mt-0"
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- INDICES DE COBERTURA -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Cobertura
        </v-card-title>
        <v-card-text
          v-if="load_cobertura"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_cobertura.filter(item => filtro.regiones.find(region => region == item.codigo) || item.codigo == 0 || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  :color="item.indice < indices_cobertura.find(ind => ind.codigo == 0).indice ? 'cardWarning' :
                        (item.indice > indices_cobertura.find(ind => ind.codigo == 0).indice ? 'cardSuccess' : '' )"
                  @click="seleccionar_sucursales_cob(item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" class="pt-2">
                <v-autocomplete
                  v-model="filtro_sucursales_cob"
                  label="Sucursales"
                  item-text="nombre"
                  item-value="id"
                  :items="sucursales.filter(suc => filtro.regiones.find(region => region == suc.region) || filtro.regiones.length == 0)"
                  :disabled="indices_cobertura.length == 0"
                  :filled="indices_cobertura.length == 0"
                  deletable-chips
                  hide-details
                  small-chips
                  clearable
                  multiple
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="pt-1">
                <v-checkbox
                  v-model="todas_suc_cob"
                  label="Todas"
                  :disabled="indices_cobertura.length == 0"
                  hide-details
                  dense
                  @click="seleccionar_all_suc_cob()"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in indices_cobertura_suc.filter(item => filtro_sucursales_cob.find(sucursal => sucursal == item.codigo))"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
      <!-- INDICES DE DEFECTUOSOS -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Defectuosos
        </v-card-title>
        <v-card-text
          v-if="load_defectuoso"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_defectuosos.filter(item => filtro.regiones.find(region => region == item.codigo) || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  @click="region_defectuosa == null ? region_defectuosa = item.codigo : (region_defectuosa == item.codigo ? region_defectuosa = null : region_defectuosa = item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-row>
              <v-col
                v-for="(item, index) in indices_defectuosos_suc.filter(item => item.region == region_defectuosa)"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
      <!-- INDICES DE OBSOLETOS -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Obsoletos
        </v-card-title>
        <v-card-text
          v-if="load_obsoleto"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_obsoletos.filter(item => filtro.regiones.find(region => region == item.codigo) || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  @click="region_obsoleta == null ? region_obsoleta = item.codigo : (region_obsoleta == item.codigo ? region_obsoleta = null : region_obsoleta = item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-row>
              <v-col
                v-for="(item, index) in indices_obsoletos_suc.filter(item => item.region == region_obsoleta)"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
      <!-- INDICES DE SOBRESTOCK -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Stock
        </v-card-title>
        <v-card-text
          v-if="load_sobrestock"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_sobrestock.filter(item => filtro.regiones.find(region => region == item.codigo) || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  @click="region_sobrestock == null ? region_sobrestock = item.codigo : (region_sobrestock == item.codigo ? region_sobrestock = null : region_sobrestock = item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-row>
              <v-col
                v-for="(item, index) in indices_sobrestock_suc.filter(item => item.region == region_sobrestock)"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
      <!-- INDICES DE QUIEBRE -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Quiebre
        </v-card-title>
        <v-card-text
          v-if="load_quiebre"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_quiebre.filter(item => filtro.regiones.find(region => region == item.codigo) || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  @click="region_quiebre == null ? region_quiebre = item.codigo : (region_quiebre == item.codigo ? region_quiebre = null : region_quiebre = item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-row>
              <v-col
                v-for="(item, index) in indices_quiebre_suc.filter(item => item.region == region_quiebre)"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
      <!-- INDICES DE INMOVILIZACION -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Inmovilización
        </v-card-title>
        <v-card-text
          v-if="load_inmovilizacion"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_inmovilizacion.filter(item => filtro.regiones.find(region => region == item.codigo) || item.codigo == 0 || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  :color="item.indice < indices_inmovilizacion.find(ind => ind.codigo == 0).indice ? 'cardSuccess' :
                        (item.indice > indices_inmovilizacion.find(ind => ind.codigo == 0).indice ? 'cardWarning' : '' )"
                  @click="seleccionar_sucursales_inm(item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" class="pt-2">
                <v-autocomplete
                  v-model="filtro_sucursales_inm"
                  label="Sucursales"
                  item-text="nombre"
                  item-value="id"
                  :items="sucursales.filter(suc => filtro.regiones.find(region => region == suc.region) || filtro.regiones.length == 0)"
                  :disabled="indices_inmovilizacion.length == 0"
                  :filled="indices_inmovilizacion.length == 0"
                  deletable-chips
                  hide-details
                  small-chips
                  clearable
                  multiple
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="pt-1">
                <v-checkbox
                  v-model="todas_suc_inm"
                  label="Todas"
                  :disabled="indices_inmovilizacion.length == 0"
                  hide-details
                  dense
                  @click="seleccionar_all_suc_inm()"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in indices_inmovilizacion_suc.filter(item => filtro_sucursales_inm.find(sucursal => sucursal == item.codigo))"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
      <!-- INDICES DE ROTACION -->
      <v-card class="mt-3">
        <v-card-title class="pb-0 pt-2 text-h5">
          <v-icon left>fas fa-chart-line</v-icon>
          Rotación de variedad
        </v-card-title>
        <v-card-text
          v-if="load_rotacion"
          class="px-16"
        >
          <v-progress-linear
            class="my-4"
            color="primary"
            height="8"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-card-text>
        <template v-else>
          <v-card-text>
            <v-row  class="pa-2">
              <v-col
                v-for="(item, index) in indices_rotacion.filter(item => filtro.regiones.find(region => region == item.codigo) || filtro.regiones.length == 0)"
                :key="index"
                cols="12" sm="6" md="3"
              >
                <v-card
                  style="cursor: pointer"
                  @click="region_rotacion == null ? region_rotacion = item.codigo : (region_rotacion == item.codigo ? region_rotacion = null : region_rotacion = item.codigo)"
                >
                  <v-card-title>
                    <span class="font-weight-regular">
                      {{ item.region }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 24px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-row>
              <v-col
                v-for="(item, index) in indices_rotacion_suc.filter(item => item.region == region_rotacion)"
                :key="index"
                cols="12" sm="6" md="2"
              >
                <v-card color="rowsel">
                  <v-card-title>
                    <span class="body-2">
                      {{ item.sucursal }}
                    </span>
                    <v-spacer></v-spacer>
                    <span style="font-size: 18px">
                      {{ item.indice }}%
                    </span>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by } from '../../util/utils'
import BtnFiltro from '../../components/util/BtnFiltro'

export default {
  data () {
    return {
      panel: 0,
      load: false,
      load_cobertura: false,
      load_defectuoso: false,
      load_obsoleto: false,
      load_sobrestock: false,
      load_quiebre: false,
      load_inmovilizacion: false,
      load_rotacion: false,
      todas_suc_cob: false,
      todas_suc_inm: false,
      region_cobertura: null,
      region_defectuosa: null,
      region_obsoleta: null,
      region_sobrestock: null,
      region_quiebre: null,
      region_inmovilizacion: null,
      region_rotacion: null,
      categorias_filtradas: [],
      indices_cobertura: [],
      indices_cobertura_suc: [],
      indices_defectuosos: [],
      indices_defectuosos_suc: [],
      indices_obsoletos: [],
      indices_obsoletos_suc: [],
      indices_sobrestock: [],
      indices_sobrestock_suc: [],
      indices_quiebre: [],
      indices_quiebre_suc: [],
      indices_inmovilizacion: [],
      indices_inmovilizacion_suc: [],
      indices_rotacion: [],
      indices_rotacion_suc: [],
      filtro_sucursales_cob: [],
      filtro_sucursales_inm: [],
      filtro: {
        regiones: [],
        rubros: [],
        categorias: [],
        tipos_deposito: []
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('dashboard/get_tipos_deposito')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['regiones']),
    ...mapState('rubros', ['rubros']),
    ...mapState('categorias', ['categorias']),
    ...mapState('genericosAcc', ['sucursales']),
    ...mapState('dashboard', ['tipos_deposito'])
  },
  components: {
    BtnFiltro
  },
  watch: {
    filtro_sucursales_cob () {
      if (this.filtro_sucursales_cob.length == this.sucursales.filter(suc => this.filtro.regiones.find(region => region == suc.region) || this.filtro.regiones.length == 0).length) {
        this.todas_suc_cob = true
      } else {
        this.todas_suc_cob = false
      }
    },
    filtro_sucursales_inm () {
      if (this.filtro_sucursales_inm.length == this.sucursales.filter(suc => this.filtro.regiones.find(region => region == suc.region) || this.filtro.regiones.length == 0).length) {
        this.todas_suc_inm = true
      } else {
        this.todas_suc_inm = false
      }
    }
  },
  methods: {
    seleccionar_sucursales_cob (region) {
      this.filtro_sucursales_cob = []
      if (region != this.region_cobertura) {
        this.sucursales.filter(suc => suc.region == region || region == 0).forEach(suc => {
          this.filtro_sucursales_cob.push(suc.id)
        })
        this.region_cobertura = region
      } else {
        this.region_cobertura = null
      }
    },
    seleccionar_all_suc_cob () {
      this.region_cobertura = null
      if (this.todas_suc_cob) {
        this.filtro_sucursales_cob = []
        this.sucursales.filter(suc => this.filtro.regiones.find(region => region == suc.region) || this.filtro.regiones.length == 0).forEach(suc => {
          this.filtro_sucursales_cob.push(suc.id)
        })
      } else {
        this.filtro_sucursales_cob = []
      }
    },
    seleccionar_sucursales_inm (region) {
      this.filtro_sucursales_inm = []
      if (region != this.region_inmovilizacion) {
        this.sucursales.filter(suc => suc.region == region || region == 0).forEach(suc => {
          this.filtro_sucursales_inm.push(suc.id)
        })
        this.region_inmovilizacion = region
      } else {
        this.region_inmovilizacion = null
      }
    },
    seleccionar_all_suc_inm () {
      this.region_inmovilizacion = null
      if (this.todas_suc_inm) {
        this.filtro_sucursales_inm = []
        this.sucursales.filter(suc => this.filtro.regiones.find(region => region == suc.region) || this.filtro.regiones.length == 0).forEach(suc => {
          this.filtro_sucursales_inm.push(suc.id)
        })
      } else {
        this.filtro_sucursales_inm = []
      }
    },
    async buscar () {
      this.region_cobertura = null
      this.region_defectuosa = null
      this.region_obsoleta = null
      this.region_sobrestock = null
      this.region_quiebre = null
      this.region_inmovilizacion = null
      this.region_rotacion = null
      this.indices_cobertura = []
      this.indices_cobertura_suc = []
      this.indices_defectuosos = []
      this.indices_defectuosos_suc = []
      this.indices_obsoletos = []
      this.indices_obsoletos_suc = []
      this.indices_sobrestock = []
      this.indices_sobrestock_suc = []
      this.indices_quiebre = []
      this.indices_quiebre_suc = []
      this.indices_inmovilizacion = []
      this.indices_inmovilizacion_suc = []
      this.indices_rotacion = []
      this.indices_rotacion_suc = []
      this.filtro_sucursales_cob = []
      this.filtro_sucursales_inm = []
      this.load = true
      this.load_cobertura = true
      this.load_defectuoso = true
      this.load_obsoleto = true
      this.load_sobrestock = true
      this.load_quiebre = true
      this.load_inmovilizacion = true
      this.load_rotacion = true
      const filtro = {
        rubros: this.filtro.rubros.join(','),
        categorias: this.filtro.categorias.join(','),
        tipos_dep: this.filtro.tipos_deposito.join(',')
      }
      // cobertura
      await this.$store.dispatch('dashboard/get_indices_cobertura', filtro)
        .then((res) => {
          this.indices_cobertura = res.data.indices
          this.indices_cobertura_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_cobertura = false
      // defectuosos
      await this.$store.dispatch('dashboard/get_indices_defectuosos', filtro)
        .then((res) => {
          this.indices_defectuosos = res.data.indices
          this.indices_defectuosos_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_defectuoso = false
      // obsoletos
      await this.$store.dispatch('dashboard/get_indices_obsoletos', filtro)
        .then((res) => {
          this.indices_obsoletos = res.data.indices
          this.indices_obsoletos_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_obsoleto = false
      // sobrestock
      await this.$store.dispatch('dashboard/get_indices_sobrestock', filtro)
        .then((res) => {
          this.indices_sobrestock = res.data.indices
          this.indices_sobrestock_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_sobrestock = false
      // quiebre
      await this.$store.dispatch('dashboard/get_indices_quiebre', filtro)
        .then((res) => {
          this.indices_quiebre = res.data.indices
          this.indices_quiebre_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_quiebre = false
      // inmovilizacion
      await this.$store.dispatch('dashboard/get_indices_inmovilizacion', filtro)
        .then((res) => {
          this.indices_inmovilizacion = res.data.indices
          this.indices_inmovilizacion_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_inmovilizacion = false
      // rotacion
      await this.$store.dispatch('dashboard/get_indices_rotacion', filtro)
        .then((res) => {
          this.indices_rotacion = res.data.indices
          this.indices_rotacion_suc = res.data.sucursales
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.load_rotacion =false
      this.load = false
    },
    get_categorias () {
      this.categorias_filtradas = []
      this.filtro.rubros.forEach(rubro => {
        let categorias = this.categorias.filter(cat => cat.rubro_codigo == rubro)
        categorias.forEach(categoria => {
          this.categorias_filtradas.push(categoria)
        })
      })
      order_list_by(this.categorias_filtradas, 'nombre')
    },
    limpiar () {
      this.region_cobertura = null
      this.region_defectuosa = null
      this.region_obsoleta = null
      this.region_sobrestock = null
      this.region_quiebre = null
      this.region_inmovilizacion = null
      this.region_rotacion = null
      this.categorias_filtradas = []
      this.filtro_sucursales_cob = []
      this.filtro_sucursales_inm = []
      this.filtro = {
        regiones: [],
        rubros: [],
        categorias: [],
        tipos_deposito: []
      }
    }
  }
}
</script>